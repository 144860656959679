import React, { useState } from 'react';
import './App.css';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={`App ${menuOpen ? 'menu-open' : ''}`}>
      <header className="header">
        <div className="header-left">
          <h1>$Miaow</h1>
        </div>
        <div className="header-right">
          <a href="https://x.com/miaowsolana" target="_blank" rel="noopener noreferrer">
            <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/x-social-media-white-icon.png" alt="Twitter" className="icon" />
          </a>
          <a href="https://t.me/+P0YAOfntHrswOGQ0" target="_blank" rel="noopener noreferrer">
            <img src="https://static-00.iconduck.com/assets.00/telegram-icon-2048x1739-6c7sghxm.png" alt="Telegram" className="icon" />
          </a>
          <a href="https://www.pump.fun/2YYUckbe4FPhQowg9rRZGjyMvyBziDWmn6N1qWDNpump" target="_blank" rel="noopener noreferrer">
            <img src="https://www.pump.fun/_next/image?url=%2Flogo.png&w=64&q=75" alt="Pump.fun" className="icon" />
          </a>
        </div>
        <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </header>

      {menuOpen && (
        <div className="menu">
          <div className="menu-content">
            <h1>$Miaow</h1>
            <a href="https://x.com/miaowsolana" target="_blank" rel="noopener noreferrer">
              <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/x-social-media-white-icon.png" alt="Twitter" className="icon" />
            </a>
            <a href="https://t.me/+P0YAOfntHrswOGQ0" target="_blank" rel="noopener noreferrer">
              <img src="https://static-00.iconduck.com/assets.00/telegram-icon-2048x1739-6c7sghxm.png" alt="Telegram" className="icon" />
            </a>
            <a href="https://www.pump.fun/2YYUckbe4FPhQowg9rRZGjyMvyBziDWmn6N1qWDNpump" target="_blank" rel="noopener noreferrer">
              <img src="https://www.pump.fun/_next/image?url=%2Flogo.png&w=64&q=75" alt="Pump.fun" className="icon" />
            </a>
          </div>
        </div>
      )}

      <div className="fanus">
        <img src="https://ucarecdn.com/a41af984-82a6-42b6-8d4c-d1d5019a324a/" style={{ '--order': '10s' }} alt="fanus1" />
        <img src="https://ucarecdn.com/3574a35f-b88a-46ea-8fce-f650a402ef6c/" style={{ '--order': '6s' }} alt="fanus2" />
        <img src="https://ucarecdn.com/cf744f3e-bc36-4d37-829d-aeb0b2751b30/" style={{ '--order': '8s' }} alt="fanus3" />
      </div>
      <div className="image-section">
        <div className="image-container">
          <img src="/cat.gif" alt="Cat" className="cat-image" />
          <img src="/border.png" alt="Border" className="border-image" />
        </div>
      </div>
      <h1>About</h1>
      <ul>
        <li>
          <div className="scroll-container">
            <div className="head">
              <span className="left"></span>
              <span className="main"></span>
              <span className="right"></span>
            </div>
            <div className="paper">
              <div className="content">
                <h2>Who is Miaow?</h2>
                a legendary feline warrior from ancient Chinese folklore, this mystical cat is said to possess extraordinary agility, strength, and intelligence, making him a formidable companion in battle adept at fighting for his comrades and defending the emperor
                {/* <span className="El-khetm">verified ✓</span> */}
              </div>
            </div>
            <div className="head">
              <span className="left"></span>
              <span className="main"></span>
              <span className="right"></span>
            </div>
          </div>
        </li>
        <h2 className="tokenomics-title">Tokenomics</h2>
        <li>
          <div className="scroll-container" onClick={toggleOpen}>
            <div className="head">
              <span className="left"></span>
              <span className="main"></span>
              <span className="right"></span>
            </div>
            <div className={`paper ${isOpen ? '' : 'close'}`}>
              <div className="content">
                <h2>$Miaow</h2>
                <ul>
                  <li>Name: Chinese Cat</li>
                  <li>Ticker: Miaow</li>
                  <li>Supply: 1b</li>
                  <li>Tax: 0%</li>
                  <li>LP burned (pump.fun)</li>
                </ul>
              </div>
            </div>
            <div className="head">
              <span className="left"></span>
              <span className="main"></span>
              <span className="right"></span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default App;